export default {
  heading: 'Playfair Display',
  body: 'Playfair Display',
  monospace: 'Menlo, monospace',
  display: 'Bacasime Antique, cursive',
  googleFonts: [
    'Roboto:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Bacasime Antique:100,200,300,400,500,600,700,800,900',
    'Playfair Display:100,200,300,400,500,600,700,800,900'
  ]
  // customFamilies: ['', ''],
  // customUrls: ['', ''],
}
