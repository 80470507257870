export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      background: 'unset',
      backgroundColor: 'white',
      padding: '0.5rem'
    },
    '.containerScrolled': {
      background: 'unset',
      backgroundColor: 'white',
      padding: '0.5rem'
    },
    '.logo': {
      position: 'static',
      transform: 'unset',
      height: '100%',
      '.image': {
        height: '100%',
        padding: '0rem',
        maxHeight: '100%'
      }
    },
    '.logoScrolled': {
      position: 'static',
      display: 'flex',
      // width: '230px',
      transform: 'unset',
      height: '100%',
      '.image': {
        height: '100%',
        padding: '0rem',
        maxHeight: '100%',
        maxWidth: '200px'
      }
    },
    '.phoneContainer': {
      display: 'none'
    },
    '.socialIconsContainer': {
      m: '0rem 0rem 0rem 1.5rem'
    },
    '.socialContainer': {
      svg: {
        path: {
          fill: 'text'
        }
      }
    },
    '.navItem': {
      fontSize: ['2rem', '', '', '1.25rem']
    },
    '.navMenu': {
      color: ['white', '', '', 'text'],
      backgroundPosition: '40% 40%',
      backgroundImage: [
        'url(https://res.cloudinary.com/gonation/image/upload/w_2500/q_auto/f_auto/v1608153153/sites/andersen-fitness/DSK_4468-Edit.jpg)',
        '',
        '',
        'unset'
      ]
    },
    '.hamburgerOpen': {
      div: {
        backgroundColor: 'white'
      }
    }
  },

  footer: {
    backgroundColor: 'white',
    boxShadow: '0 0 30px rgba(0,0,0,.2)',
    '.address': {
      display: 'none'
    },
    '.gonationLogo': {
      filter: 'brightness(0)'
    },
    '.multiButtonContainer': {
      a: {
        border: 'none',
        borderBottom: '1px solid black',
        backgroundColor: 'transparent',
        color: '#111',
        '&:hover': {
          background: 'primary',
          color: 'white'
        }
      }
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'white',
    display: 'none !important',
    '#directions-cta': {
      display: 'none'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {},
  subtitle: {},
  text: {},

  sideBySide1: {
    backgroundColor: 'black',
    color: 'white',
    '.lazyload-wrapper': {
      maxHeight: '75vh'
    },
    '.content': {},
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '1px solid white'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': { variant: 'customVariants.text' },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    variant: 'customVariants.sideBySide1',
    minHeight: ['100vh', '', 'unset'],
    mt: ['75px', '', '', '79px'],
    '.content': {
      p: '5vh 5vw'
    },
    '.title': {
      fontSize: ['2rem', '2.25rem', '2.75rem', '3rem', '3.5rem'],
      m: '0rem',
      borderBottom: '1px solid',
      mb: '1.5rem',
      pb: '0.25rem'
    },
    '.subtitle': {
      color: '#cbcfe3',
      mb: '2rem'
    },
    '.text': {
      fontSize: ['28px'],
      opacity: '0.8',
      '*': {
        color: 'white',
        fontSize: ['28px']
      }
    }
  },

  homepageHeroShout: {
    display: 'none !important'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageBoxes: {
    padding: ['3rem 1rem', '', '', '3rem 2.5rem'],
    '.box': {
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(33.3% - 3rem)'],
      margin: ['', '', '1rem', '1.5rem'],
      // backgroundColor: 'white',
      padding: '1rem',
      flexGrow: '1',
      ':hover': {
        '.image': {
          filter: 'brightness(0.6)'
        },
        '.title': { borderColor: 'black' }
      }
    },
    '.image': {
      transition: 'all ease-in-out 0.5s',
      height: '550px',
      marginBottom: '1rem',
      objectFit: 'cover'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.4rem', '1.5rem', '2rem', '2.25rem', '2.5rem'],
      marginBottom: '1.5rem',
      borderBottom: 'solid 1px',
      borderColor: 'background',
      width: 'fit-content',
      transition: 'all ease-in-out 0.5s'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontWeight: '300',
      marginBottom: '0.5rem',
      color: '#7e7e7e'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageQuote: {
    backgroundColor: 'black',
    p: '20vh 10vw',
    color: 'white',
    '.section': {
      maxWidth: '1000px'
    },
    '.title': {
      fontSize: ['2rem', '2.25rem', '2.75rem', '3rem', '3.5rem']
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.homepageBlock'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide2'
  },

  homepageShout: {
    backgroundColor: 'white',
    '.content': {
      padding: '10vh 10vw',
      alignItems: 'flex-start',
      '*': {
        textAlign: 'left'
      },
      '.title': {
        fontSize: ['1.25rem', '', '1.75rem'],
        m: '0rem',
        borderBottom: '1px solid',
        mb: '1.5rem',
        pb: '0.25rem',
        width: 'auto'
      },
      '.date': {
        m: '0rem',
        opacity: '0.5'
      },
      '.text': {
        fontSize: ['2rem', '', '2.75rem'],
        m: '2rem 0rem 0rem'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {},

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {},

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: 'secondary',
    color: 'white',
    '.address': {
      display: 'none'
    },
    '.button': {
      color: 'white'
    },
    '.inputField, .textField ': {
      borderColor: 'primary',
      borderRadius: '0px',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? =======  team   ========
  // ? ========================
  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {},

  blogPage: {}
}
